import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Img from '../components/img';
// import Pagination from "../components/pagination/Pagination"
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const TagList = ({ pageContext, data, location }) => {
  const { tagSlug } = pageContext;
  const { totalCount, nodes } = data.allMarkdownRemark;

  return (
    <Layout
      location={location}
      title={tagSlug}
    >
      <Seo
        title={tagSlug}
        location={location}
        type="tag-list"
        discription={`${tagSlug}一覧記事です。`}
      />
      <TagListLayoutFlexContainer>
        <TagListLayoutContainer>
          <BlogListHeader>
            <h2>{tagSlug}</h2>
            <p>現在 {totalCount} 記事あります</p>
          </BlogListHeader>
          <TagListInner>
            {nodes.map(post => {
              const title = post.frontmatter.title || post.fields.slug;
              const tags = post.frontmatter.tags.sort();

              return (
                <BlogCardContents>
                  <Link
                    to={post.fields.slug}
                    itemProp="url"
                  >
                    <figure>
                      <Img
                        alt={title}
                        image={post.frontmatter.hero}
                        className="img-fluid"
                      />
                    </figure>
                    <h2>{title}</h2>
                    <BlogDetailContainer>
                      <DetailTimeArea>
                        <FontAwesomeIcon icon={faClock} />
                        <time>{post.frontmatter.date}</time>
                      </DetailTimeArea>
                    </BlogDetailContainer>
                  </Link>
                  <DetailCategoryArea className="">
                    {tags.map((tag, index) => {
                      return (
                        <Link
                          key={`tag${index}`}
                          to={`/tags/${tag}/`}
                        >
                          {tag}
                        </Link>
                      );
                    })}
                  </DetailCategoryArea>
                </BlogCardContents>
              );
            })}
          </TagListInner>
          {/* ページネーション */}
          {/* <Pagination
            totalPageNumber={totalPageNumber}
            current={current}
            type="tags"
            path={tagPath}
          /> */}
        </TagListLayoutContainer>
        {/* サイドバー */}
      </TagListLayoutFlexContainer>
    </Layout>
  );
};

export default TagList;

export const pageQuery = graphql`
  query ($tagSlug: String, $draft: [Boolean]!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          pagetype: { eq: "blog" }
          tags: { in: [$tagSlug] }
          draft: { in: $draft } # 下書きのページは表示させない
        }
      }
    ) {
      # 記事総数取得
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          hero
          tags #追加
        }
      }
    }
  }
`;

const TagListLayoutFlexContainer = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: center;
`;

const TagListLayoutContainer = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
  width: 100%;

  @media (min-width: 1200px) {
    flex: 0 0 auto;
  }
`;

const TagListInner = styled.div`
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const BlogListHeader = styled.div`
  h2 {
    text-align: center;
    margin-bottom: 2.3rem;

    & time {
      text-align: left;
    }

    &::after {
      margin: 8px auto;
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background-color: #0a0b37;
    }
  }

  p {
    text-align: center;
  }
`;

const BlogDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 1200px) and (max-width: 1200px) {
    display: block;
  }
`;

const DetailTimeArea = styled.div`
  & time {
    display: inline-block;
    margin-left: 0.2rem;
    font-size: 0.9rem;
  }
`;

const DetailCategoryArea = styled.div`
  display: flex;
  & p {
    margin-left: 1rem;
    &:first-child {
      margin-left: 0px;
    }
  }

  & > a {
    display: inline-block;
    padding: 4px 12px;
    background-color: #0a0b37;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
    transition: all 0.3s;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: #ffffff;
      opacity: 0.6;
    }
  }
`;

const BlogCardContents = styled.article`
  padding: 0.3rem;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    width: 33.33333%;
    flex: 0 0 auto;
    padding: 0.7rem;
  }

  & > a {
    @media (min-width: 1200px) {
      min-height: 250px;
    }

    display: block;
    color: #5a5a5a;
    transition: all 0.3s;
    &:hover {
      color: #5a5a5a;
      opacity: 0.7;
    }
  }

  & figure {
    position: relative;
    overflow: hidden;

    &::after {
      content: 'READ MORE';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      opacity: 0;
      transition: 0.3s;
    }

    &:hover::after {
      opacity: 1;
      letter-spacing: 1.5;
    }

    & img {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 4px -4px rgba(0, 0, 0, 0.1);
    }

    &:hover {
      transform: scale(1);
    }
  }

  & h2 {
    font-size: 1.3rem;
    margin-bottom: 1.3rem;
  }
`;
